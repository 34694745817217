<template>
    <Row :gutter="20"> 
        <matchTable :sport_id="sport_id" :season_id="season_id"></matchTable>
    </Row>
</template>
<script>

import matchTable from '../match_table/table.vue';

export default {
    name: "season-detail",
    props: {
        sport_id: Number,   // 运动id
        season_id: Number,   // 联赛id
    },
    components: {
        matchTable,
    },
    data () {
        let self = (window.x = this)
        return {
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>