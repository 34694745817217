<template>
    <div style="width:100%">
        <Drawer
            v-model="edit_table_drawer"
            width="600"
            :mask-closable="true"
        >
            <tableEdit :sport_id="sport_id" :table_id="edit_table_id" @closePage='closeTableEditPage' ></tableEdit>
        </Drawer>

        <Drawer
            v-model="edit_table_row_drawer"
            width="600"
            :mask-closable="true"
        >
            <tableRowEdit :sport_id="sport_id" :row_id="edit_table_row_id" @closePage='closeTableRowEditPage'></tableRowEdit>
        </Drawer>

        <Tabs v-model="table_id" capture-focus @on-change='onTableIdChange' :animated="false">
            <TabPane v-for="item in tables" :key="parseInt(item.id)" :label="getTableTabLabel(item)" :name="String(item.id)" >
                <!-- {{item }} - {{table_id}} -->
                <div >
                    <Table
                        size="small"
                        stripe
                        :columns="row_columns"
                        :data="table_rows[item.id]"
                    >
                    </Table>
                </div>
            </TabPane>
            <Row slot="extra">
                <Col>
                    <Button @click="edit_table_id=parseInt( table_id );edit_table_drawer=true">编辑</Button>
                </Col>
                <Col>
                    <Tooltip max-width='100' >
                        <!-- <span class="text-main">数据锁: </span><i-switch v-model="locked" @on-change="lockedChanged" /> -->
                        <div slot="content">
                            <p>锁定后只能人工编辑,不会自动覆盖;</p>
                            <p><i>如果没有锁定,可能会被机器覆盖掉</i></p>
                        </div>
                    </Tooltip>
                </Col>
            </Row>
        </Tabs>
    </div>
</template>

<script>

import { getTable, getTableRow } from '@/api/ballsports/base/season';
import tableEdit from './table_edit.vue';
import tableRowEdit from './table_row_edit.vue';

const editButton = (vm, h, params) => {
    return h(
        'Button',
        {
            props: {
                type: 'success',
                size: 'small'
            },
            on: {
                click: () => {
                    // 展示模态框
                    vm.edit_table_row_id = params.row.id;
                    vm.edit_table_row_drawer = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: 'season_table',
    props: [ 'sport_id', 'season_id',],
    data() {
        let self = (window.x = this)
        return {
            table_id: null,
            tables  : [],
            edit_table_drawer: false,
            edit_table_id : 0,
            edit_table_row_drawer: false,
            edit_table_row_id : 0,
            table_rows: {},
            row_columns: [
                {
                    title: 'id',
                    key: 'id',
                    width:100,
                },
                {
                    title: '战队',
                    key: 'team',
                    width:120,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var team = currentRow.team;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, team.name),
                            ]
                        )
                    }
                },
                {
                    title: '排名',
                    key: 'position',
                },
                // {
                //     title: '升降级id',
                //     key: 'promotion_id',
                // },
                // {
                //     title: '积分(杯赛)',
                //     key: 'points',
                // },
                // {
                //     title: '排序,手动排名',
                //     key: 'order',
                // },
                // {
                //     title: '是否实时',
                //     key: 'is_live',
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         var is_live = currentRow.is_live;
                //         return h(
                //             'div', [
                //                 h('span', {
                //                     attrs: {
                //                         class: 'text-main'
                //                     },
                //                 }, is_live? '是': '否'),
                //             ]
                //         )
                //     }
                // },
                {
                    title: '胜场',
                    key: 'detail/won',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.won),
                            ]
                        )
                    }
                },
                {
                    title: '负场',
                    key: 'detail/lost',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.lost),
                            ]
                        )
                    }
                },
                {
                    title: '胜率',
                    key: 'detail/won_rate',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.won_rate),
                            ]
                        )
                    }
                },
                {
                    title: '胜场差',
                    key: 'detail/game_back',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.game_back),
                            ]
                        )
                    }
                },
                {
                    title: '主场',
                    key: 'detail/home',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.home),
                            ]
                        )
                    }
                },
                {
                    title: '客场',
                    key: 'detail/away',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.away),
                            ]
                        )
                    }
                },
                {
                    title: '场均得分',
                    key: 'detail/points_avg',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.points_avg),
                            ]
                        )
                    }
                },
                {
                    title: '场均失分',
                    key: 'detail/points_against_avg',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.points_against_avg),
                            ]
                        )
                    }
                },
                {
                    title: '场均净胜',
                    key: 'detail/diff_avg',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.diff_avg),
                            ]
                        )
                    }
                },

                {
                    title: '近期战绩',
                    key: 'detail/streaks',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.streaks),
                            ]
                        )
                    }
                },
                {
                    title: '赛区胜-负',
                    key: 'detail/division',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.division),
                            ]
                        )
                    }
                },

                {
                    title: '近10场胜-负',
                    key: 'detail/last_10',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let detail = currentRow.detail;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, detail.last_10),
                            ]
                        )
                    }
                },
                {
                    title: '备注(中文)',
                    key: 'note_zh',
                    width:100,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, currentRow.note_zh),
                            ]
                        )
                    }
                },
                {
                    title: '备注(英文)',
                    key: 'note_en',
                    width:100,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h(
                            'div', [
                                h('span', {
                                    attrs: {
                                        class: 'text-main'
                                    },
                                }, currentRow.note_en),
                            ]
                        )
                    }
                },
                
                {
                    title: '操作',
                    key: 'deleted',
                    align: 'center',
                    render (h, params) {
                        return [
                            // delButton(self, h, params),
                            editButton(self, h, params)
                        ]
                    }
                }
            ],
            // 对阵图数据 是否加锁
            locked: false,
        }
    },
    created() {
    },
    watch:{
        table_id(value){
            let self = this;
            self.onTableIdChange();
        },
        // end
    },
    mounted () {
        let self = this;
        self.getData();
    },
    components: {
        // roundEdit,
        // matchupEdit,
        tableEdit,
        tableRowEdit,
    },
    methods: {
        // 获取label名称
        getTableTabLabel(item){
            // return String( item.id ) ;
            // 1-赛季 2-预选赛 3-小组赛 4-季前赛 5-常规赛 6-淘汰赛(季后赛)
            let scope = item.scope;
            var scope_text = {1:'赛季', 2:'预选赛', 3:'小组赛', 4:'季前赛', 5:'常规赛', 6:'淘汰赛(季后赛)'}[scope] || ''
            return (h) => h('div', [
                h('span', {class: "text-main"}, scope_text + ' - ' ),
                h('span', {class: "text-main"}, item.name_zh || item.name_en || item.name_zht || ''),
            ])
        },
        // end 
        // 获取数据
        getData(){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                season_id: self.season_id,
            };
            getTable(params).then(response => {
                if (response.data.code === 0) {
                    self.tables = response.data.data.list;
                    if (self.tables.length > 0){
                        self.table_id = String(self.tables[0].id)
                    }
                    self.locked = response.data.locked || false;
                }else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        getRowsData(table_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                table_id: table_id,
            }
            getTableRow(params).then(response => {
                if (response.data.code === 0) {
                    self.$set( self.table_rows, table_id, response.data.data.list);
                    // self.table_rows[table_id] = response.data.data.list;
                }else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 

        onTableIdChange(){
            let self = this;
            // console.log(self.table_id);
            let table_id = parseInt(self.table_id);
            if (table_id > 0){
                // 获取是否存在列表
                if ( ! self.table_rows[table_id]){
                    // 查询数据
                    self.getRowsData(table_id);
                }
            }
        },
        // end 
        closeTableEditPage(new_data){
            let self= this;
            self.edit_table_drawer = false;
            // 更新名称
            // console.log(new_data);
            self.tables.forEach(table => {
                if (table.id === new_data.id){
                    table.name_zh = new_data.name_zh;
                    table.name_en = new_data.name_en;
                    table.name_zht = new_data.name_zht;
                }
            })
        } ,
        // end
        closeTableRowEditPage(new_data){
            let self= this;
            self.edit_table_row_drawer = false;
            // 重新获取排名数据
            let table_id = parseInt(self.table_id);
            self.getRowsData(table_id);
        } ,
        // end 
        lockedChanged(status){
            let self = this;
            // console.log(status);
            let locked = status? 1: 0;
            // 更新到线上
            let params = {
                sport_id: self.sport_id,
                season_id: self.season_id,
                locked: locked,
            }
            updateTableLock(params).then(response => {
                if (response.data.code === 0) {
                    self.$Message.success('更新成功!');
                }else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
    },
    //
    computed: {
        // is_promotion: {
        //     get: function(){
        //         let self = this;
        //         if (self.edit_match.is_promotion === 1){
        //             return true;
        //         }else{
        //             return false
        //         }
        //     },
        //     set: function(newValue){
        //         let self = this;
        //         self.edit_match.is_promotion = newValue? 1: 0;
        //         self.edit_match.promot_name = '';
        //     },
        // },
    }
}
</script>
