<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="中文名称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_zh" :class="locks[String(type)+'-1-'+'name_zh'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="阶段: ">
                <Row>
                    <Col span="20" >
                        <tournamentSearch v-model="data.stage" :sport_id="sport_id" :season_id="data.season_id" ></tournamentSearch>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="英文名称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_en" :class="locks[String(type)+'-1-'+'name_en'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="中文繁体: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_zht" :class="locks[String(type)+'-1-'+'name_zht'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            
            <FormItem label="统计范围: ">
                <Row>
                    <Col span="20" >
                        <!-- 1-赛季 2-预选赛 3-小组赛 4-季前赛 5-常规赛 6-淘汰赛(季后赛) -->
                        <Select v-model="data.scope" :class="locks[String(type) + '-1-' + 'scope'] === 1? 'locked-select': '' ">
                            <Option :value="0" key="0">未知</Option>
                            <Option :value="1" key="1">赛季</Option>
                            <Option :value="2" key="2">预选赛</Option>
                            <Option :value="3" key="3">小组赛</Option>
                            <Option :value="4" key="4">季前赛</Option>
                            <Option :value="5" key="5">常规赛</Option>
                            <Option :value="6" key="6">淘汰赛(季后赛)</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="排序: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.order" :class="locks[String(type)+'-1-'+'order'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import tournamentSearch from '../tournament/search.vue';
import { getTable, updateTable } from '@/api/ballsports/base/season';

export default {
    name: "table-edit",
    props: {
        sport_id: Number,   // 运动id
        table_id: Number,   // 轮次id
    },
    components: {
        tournamentSearch
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 3,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                stage: {},
            },
            locks: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.table_id && self.table_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.table_id,
                }
                getTable( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
            }
            
        },
        // end 
        emit(params){
            this.$emit('closePage', params)
        },

        save() {
            let self = this;
            // console.log(self.data)
            let params = {
                id: self.data.id,
                sport_id: self.sport_id,
                stage_id: self.data.stage.id,
                name_zh: self.data.name_zh,
                name_en: self.data.name_en,
                name_zht: self.data.name_zht,
                scope : self.data.scope,
                order : self.data.order,
            };
            // console.log(params);self.emit(params);
            updateTable(params).then(response => {
                if (response.data.code == 0) {
                    self.emit(params);
                    self.$Message.success("保存成功")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
        let self = this;
        // self.getData();
    },
    watch: {
        'table_id': {
            handler(value){
                // console.log("Detail Get Data", value);
                let self = this;
                self.table_id = parseInt(value);
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>